<template>
  <template v-if="props.essenceSource === 'button'">
    <Button
      :class="
        onlyIcon
          ? 'p-button-text ' + className
          : 'p-button-outlined p-button-secondary p-button-sm p-delete-button delete-btn'
      "
      type="button"
      :disabled="disabled"
      @click.stop="openConfirmation"
    >
      <i :class="showIcon ? 'pi pi-trash' : ''"></i>
      <span v-if="title.length > 0" class="ml-2 p-1">{{ title }}</span>
    </Button>
  </template>
  <template v-else-if="props.essenceSource === 'action_menu'">
    <li :class="className" @click="openConfirmation">
      <i class="pi pi-trash"></i>
      <span>Delete</span>
    </li>
  </template>
  <template v-if="props.essenceSource === 'badge'">
    <Button
      :class="className"
      class="p-button-icon-only p-button-danger p-button-outlined badge-button"
      :text="isText"
      @click="openConfirmation"
    >
      <i class="pi pi-trash"></i>
    </Button>
  </template>
  <Dialog header="Confirmation" v-model:visible="displayConfirmation" :style="{ width: '350px' }" :modal="true">
    <div class="flex align-items-center justify-content-center">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
      <span v-if="lang === 'en'">Are you sure you want to proceed?</span>
      <span v-if="lang === 'fa'">آیا از حذف این مورد اطلمینان دارید؟</span>
    </div>
    <template #footer>
      <div v-if="lang === 'en'">
        <Button label="No" icon="pi pi-times" @click="closeConfirmation" class="p-button-text" />
        <Button label="Yes" icon="pi pi-check" @click="onConfirm" class="p-button-text" autofocus />
      </div>
      <div v-if="lang === 'fa'" style="direction: rtl">
        <Button label="خیر" icon="pi pi-times" @click="closeConfirmation" class="p-button-text" />
        <Button label="بله" icon="pi pi-check" @click="onConfirm" class="p-button-text" autofocus />
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { useToast } from 'primevue/usetoast';
import GlobalService from '@/service/GlobalService';
import { ref } from 'vue'; // Correct import
import emitter from '@/utils/eventBus';

const GlobalServiceItem = new GlobalService();
const toast = useToast();
const displayConfirmation = ref(false);

const props = defineProps({
  essenceId: { type: Number, required: true },
  essenceIdSecondary: { type: Number, required: false, default: 0 },
  essenceType: {
    type: String,
    required: true,
    validator: (value) =>
      [
        'country',
        'city',
        'airline',
        'service',
        'packageItem',
        'trackingStatus',
        'paymentMethod',
        'baseLocation',
        'reference',
        'CarryingTerms',
        'CustomerAddress'
      ].includes(value)
  },
  essenceTypeSecondary: { type: String, required: false, default: '' },
  essenceSource: {
    type: String,
    required: true,
    validator: (value) => ['action_menu', 'button', 'badge', 'direct'].includes(value)
  },
  lang: { type: String, required: false, default: 'en' },
  emitterName: { type: String, required: false, default: '' },
  showIcon: { type: Boolean, required: false, default: false },
  onlyIcon: { type: Boolean, required: false, default: false },
  title: { type: String, required: false, default: '' },
  className: { type: String, required: false, default: '' },
  sectionName: { type: String, required: false, default: '' },
  disabled: { type: Boolean, required: false, default: false },
  isText: { type: Boolean, required: false, default: false }
});
const openConfirmation = () => {
  displayConfirmation.value = true;
};

const closeConfirmation = () => {
  displayConfirmation.value = false;
};
const onConfirm = () => {
  GlobalServiceItem.essenceDelete(
    props.essenceType,
    props.essenceId,
    props.essenceIdSecondary,
    props.essenceTypeSecondary
  )
    .then(() => {
      if (props.emitterName && props.emitterName.length) {
        emitter.emit(props.emitterName);
      } else {
        emitter.emit(`refresh-table-for-${props.essenceType}s`);
      }
      toast.add({
        severity: 'success',
        summary: 'Success',
        detail: props.lang === 'en' ? 'Deleted Successfully' : 'با موفقیت حذف شد',
        life: 3000
      });
      closeConfirmation();
    })
    .catch((error) => {
      toast.add({
        severity: 'error',
        summary: 'Error',
        detail: error,
        life: 3000
      });
    });
};
</script>
<style scoped>
.badge-button {
  vertical-align: middle;
  height: 26px !important;
  width: 26px !important;
}

.delete-btn i {
  width: 20px !important;
  height: 20px !important;
  display: flex !important;
  line-height: 20px !important;
  justify-content: center !important;
  align-items: center !important;
}

.delete-btn {
  border: 1px solid #ef5252 !important;
  color: #ef5252 !important;
  padding: 6px !important;
  margin-left: 8px !important;
}

.delete-btn:hover {
  border: 1px solid #ef5252 !important;
  color: #ef5252 !important;
}
.locationCustomerAction li i {
  width: 20px !important;
  height: 20px !important;
  display: inline-flex !important;
  line-height: 20px !important;
  justify-content: center !important;
  align-items: center !important;
}
.locationCustomerAction li {
  padding: 8px 0 8px 0 !important;
  line-height: 0 !important;
}

.p-button-secondary:enabled:focus {
  box-shadow: 0 0 0 1px #35425e !important;
}

.p-overlaypanel-content {
  padding: 12px !important;
}
.p-button-icon-only.p-delete-button {
  width: 32px !important;
  height: 32px !important;
}

.SelectActionSection li {
  padding: 8px 0 8px 0;
  line-height: 0;
}
.SelectActionSection ul li i {
  width: 20px;
  height: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-right: 8px;
}
</style>
