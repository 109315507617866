<template>
  <ul class="layout-menu">
    <template v-for="(item, i) in model" :key="item">
      <app-menu-item v-if="!item.separator" :item="item" :index="i"></app-menu-item>
      <li v-if="item.separator" class="menu-separator"></li>
    </template>
  </ul>
</template>

<script setup>
import { ref } from 'vue';

import AppMenuItem from './AppMenuItem.vue';

const model = ref([
  {
    label: 'Home',
    items: [
      { label: 'Dashboard', icon: 'home', to: '/' },
      {
        label: 'References',
        icon: 'box-archive',
        items: [
          {
            label: 'References List',
            icon: 'file-invoice',
            to: '/References'
          },
          {
            label: 'Bulk Action',
            icon: 'list',
            to: '/bulk-tracking'
          },
          {
            label: 'Pending Pickups',
            icon: 'truck',
            to: '/pending-pickup-list'
          },
          {
            label: 'Pickup Management',
            icon: 'truck',
            to: '/pickup-list'
          }
        ]
      },
      {
        label: 'Financial',
        icon: 'file-invoice-dollar',
        items: [
          {
            label: 'Invoices List',
            icon: 'file-invoice',
            to: '/Invoices'
          }
        ]
      },
      { label: 'My Pickups', icon: 'truck', to: '/my-pickups' }
    ]
  },
  {
    label: 'Reports',
    icon: 'gear',
    items: [
      {
        label: 'Reports',
        icon: 'chart-simple',
        items: [
          {
            label: 'خروجی گزارش ترافیک، درآمد و میانگین نرخ به تفکیک روزهای ماه',
            icon: 'chart-simple',
            to: '/daily-report-traffic-income'
          },
          {
            label: 'گزارش مقایسه ای عملکرد کلیه سرویسها بر اساس ماه',
            icon: 'chart-simple',
            to: '/date-report-traffic-income'
          },
          {
            label: 'گزارش ترافیک باجه های معطله در ماه',
            icon: 'chart-simple',
            to: '/undelivered-package-report'
          },
          {
            label: 'گزارش ترافیک و درآمد سرویس کرایه در مقصد',
            icon: 'chart-simple',
            to: '/cod-service-report'
          },
          {
            label: 'گزارش ترافیک، درآمد و میانگین نرخ',
            icon: 'chart-simple',
            to: '/traffic-revenue-average-rate-reports'
          },
          {
            label: 'گزارش ترافیک توقف مرسولات در گمرک ماهیانه',
            icon: 'chart-simple',
            to: '/stock-in-customs'
          },
          {
            label: 'گزارش عملکرد ترافیک و درآمد روزانه در ماه',
            icon: 'chart-simple',
            to: '/total-count-income-month'
          },
          {
            label: 'گزارش سیر مرسولات',
            icon: 'chart-simple',
            to: '/average-shipping-time'
          },
          {
            label: 'گزارش سیر مرسولات بین المللی',
            icon: 'chart-simple',
            to: '/average-shipping-international-time'
          },
          {
            label: 'گزارش ترافیک، درآمد و میانگین نرخ به تفکیک مرسوله',
            icon: 'chart-simple',
            to: '/traffic-income-average-per-package'
          },
          {
            label: 'گزارش ترافیک مرسولات وارده به تفکیک استان',
            icon: 'chart-simple',
            to: '/traffic-report-of-incoming-shipment-by-province'
          },
          {
            label: 'گزارش ترافیک/درآمد فراوانی وزنی مرسولات خارجه',
            icon: 'chart-simple',
            to: '/report-per-weight-and-package-type'
          },
          {
            label: 'گزارش ترافیک و درآمد سرویس توزیع مجدد از باجه های معطله',
            icon: 'chart-simple',
            to: '/report-revenue-from-suspended-counters'
          },
          {
            label: 'گزارش آمار کد پستی و کد ملی ( فرستنده و گیرنده)',
            icon: 'chart-simple',
            to: '/national-code-and-postal-code-report'
          },
          {
            label: 'گزارش مرسولات سیر خارج از تعهد',
            icon: 'chart-simple',
            to: '/expired-delivery-report'
          },
          {
            label: 'گزارش وضعیت توزیع مرسولات',
            icon: 'chart-simple',
            to: '/delivered-package-report'
          },
          {
            label: 'گزارش بر حسب علت عدم توزیع',
            icon: 'chart-simple',
            to: '/non-distribution-report'
          },
          {
            label: 'گزارش بر حسب درصد مرسولات توزیع و توزیع نشده و مراجعه بار اول',
            icon: 'chart-simple',
            to: '/delivered-package-percent-report'
          }
        ]
      }
    ]
  },
  {
    label: 'Setting',
    icon: 'gear',
    items: [
      {
        label: 'App Setting',
        icon: 'gear',
        items: [
          {
            label: 'Countries',
            icon: 'earth-asia',
            to: '/countries'
          },
          {
            label: 'Cities',
            icon: 'city',
            to: '/cities'
          },
          {
            label: 'Airlines',
            icon: 'plane-up',
            to: '/airlines'
          },
          {
            label: 'Services',
            icon: 'rectangle-list',
            to: '/services'
          },
          {
            label: 'Packaging',
            icon: 'box',
            to: '/package-items'
          },
          {
            label: 'Tracking Statuses',
            icon: 'shuffle',
            to: '/tracking-status'
          },
          {
            label: 'Payment Methods',
            icon: 'credit-card',
            to: '/payment-methods'
          },
          {
            label: 'Base Locations',
            icon: 'building',
            to: '/base-locations'
          },
          {
            label: 'Shipping Terms',
            icon: 'building',
            to: '/carrying-term'
          }
        ]
      }
    ]
  }
]);
</script>

<style lang="scss" scoped></style>
