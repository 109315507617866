<template>
  <div class="layout-topbar">
    <router-link to="/" class="layout-topbar-logo">
      <img src="/layout/images/main-logo.png" alt="logo" />
    </router-link>

    <button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle()">
      <i class="pi pi-bars"></i>
    </button>

    <button class="p-link layout-topbar-menu-button layout-topbar-button" @click="onTopBarMenuButton()">
      <i class="pi pi-ellipsis-v"></i>
    </button>

    <div class="layout-topbar-menu" :class="topbarMenuClasses">
      <button @click="toggleSelectAction" class="p-link layout-topbar-button">
        <i class="pi pi-user"></i>
        <span>Profile</span>
      </button>
      <OverlayPanel
        ref="SelectActionSection"
        class="customer-action-overlay custom-table-action-w"
        :breakpoints="{ '640px': '210px' }"
      >
        <div class="SelectActionSection">
          <ul>
            <li>
              <i class="pi pi-user"></i>
              <span>Profile</span>
            </li>
            <li @click="logOutUser">
              <i class="pi pi-sign-out"></i>
              <span>Logout</span>
            </li>
          </ul>
        </div>
      </OverlayPanel>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { useLayout } from '@/layout/composables/layout';
import OverlayPanel from 'primevue/overlaypanel';
import GlobalService from '@/service/GlobalService';
import emitter from '@/utils/eventBus';
import { logout } from '@/utils/logout';
const GlobalServiceItem = ref(new GlobalService());

const { onMenuToggle } = useLayout();

const SelectActionSection = ref(OverlayPanel);
const outsideClickListener = ref(null);
const topbarMenuActive = ref(false);

const toggleSelectAction = (event) => {
  SelectActionSection.value?.toggle(event);
};

onMounted(() => {
  bindOutsideClickListener();
});

onBeforeUnmount(() => {
  unbindOutsideClickListener();
});

const logOutUser = () => {
  GlobalServiceItem.value
    .logOut()
    .then(() => {
      logout();
    })
    .catch((error) => {
      emitter.emit('toast-axios', error);
    });
};

const onTopBarMenuButton = () => {
  topbarMenuActive.value = !topbarMenuActive.value;
};
const topbarMenuClasses = computed(() => {
  return {
    'layout-topbar-menu-mobile-active': topbarMenuActive.value
  };
});

const bindOutsideClickListener = () => {
  if (!outsideClickListener.value) {
    outsideClickListener.value = (event) => {
      if (isOutsideClicked(event)) {
        topbarMenuActive.value = false;
      }
    };
    document.addEventListener('click', outsideClickListener.value);
  }
};
const unbindOutsideClickListener = () => {
  if (outsideClickListener.value) {
    document.removeEventListener('click', outsideClickListener);
    outsideClickListener.value = null;
  }
};
const isOutsideClicked = (event) => {
  if (!topbarMenuActive.value) return;

  const sidebarEl = document.querySelector('.layout-topbar-menu');
  const topbarEl = document.querySelector('.layout-topbar-menu-button');

  return !(
    sidebarEl.isSameNode(event.target) ||
    sidebarEl.contains(event.target) ||
    topbarEl.isSameNode(event.target) ||
    topbarEl.contains(event.target)
  );
};
</script>

<style lang="scss" scoped></style>
