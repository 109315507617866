<template>
  <div class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
    <div class="flex flex-column align-items-center justify-content-center">
      <img :src="logoUrl" alt="Sakai logo" class="mb-5 w-6rem flex-shrink-0" />
      <div
        style="
          border-radius: 56px;
          padding: 0.3rem;
          background: linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%);
        "
      >
        <div class="w-full surface-card py-8 px-5 sm:px-8" style="border-radius: 53px">
          <div class="text-center mb-5">
            <div class="text-900 text-3xl font-medium mb-3">HOMA EXPRESS</div>
            <span class="text-600 font-medium">Sign in to continue</span>
          </div>

          <div>
            <label for="email1" class="block text-900 text-xl font-medium mb-2">Email</label>
            <InputText
              id="email1"
              type="text"
              placeholder="Email address"
              class="w-full md:w-30rem mb-5"
              style="padding: 1rem"
              v-model="email"
            />

            <label for="password1" class="block text-900 font-medium text-xl mb-2">Password</label>
            <Password
              id="password1"
              v-model="password"
              placeholder="Password"
              :toggleMask="true"
              class="w-full mb-3"
              inputClass="w-full"
              :inputStyle="{ padding: '1rem' }"
            ></Password>

            <div class="flex align-items-center justify-content-between mb-5 gap-5">
              <a class="font-medium no-underline ml-2 text-right cursor-pointer" style="color: var(--primary-color)"
                >Forgot password?</a
              >
            </div>
            <Button :loading="loading" label="Login" class="w-full p-3 text-xl" @click="handleLogin"></Button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <AppConfig simple />
</template>
<script setup lang="ts">
import { useLayout } from '@/layout/composables/layout';
import { ref, computed } from 'vue';
import AppConfig from '../layout/AppConfig.vue';
import { saveCredentials, saveToken } from '@/utils/auth';
import GlobalService from '../service/GlobalService';
import { useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import mitt from 'mitt';

const GlobalServiceItem = ref(new GlobalService());
const { layoutConfig } = useLayout();
const email = ref('');
const password = ref('');
const loading = ref(false);
const router = useRouter();
const toast = useToast();
const emitter = mitt();
const logoUrl = computed(() => {
  return `/layout/images/${layoutConfig.darkTheme.value ? 'logo-white' : 'logo-dark'}.svg`;
});
const handleLogin = async () => {
  loading.value = true;
  const loginData = {
    email: email.value,
    password: password.value
  };
  await GlobalServiceItem.value
    .login(loginData)
    .then((data) => {
      saveToken(data.data.data.token);
      saveCredentials({ username: data.data.data.user.email });
      emitter.emit('show-alert', {
        message: 'Login successful!',
        type: 'success'
      });
      router.push('/');
    })
    .catch(() => {
      toast.add({
        severity: 'error',
        summary: 'Login Failed',
        life: 2000
      });
      emitter.emit('show-alert', {
        message: 'Login failed. Please check your credentials and try again.',
        type: 'danger'
      });
    })
    .finally(() => {
      loading.value = false;
    });
};
</script>

<style scoped>
.pi-eye {
  transform: scale(1.6);
  margin-right: 1rem;
}

.pi-eye-slash {
  transform: scale(1.6);
  margin-right: 1rem;
}
</style>
