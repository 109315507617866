// auth.js

// Save token to localStorage
export function saveToken(token) {
  console.log('token is:' + token);
  localStorage.setItem('user_token', token);
}

// Get token from localStorage
export function getToken() {
  return localStorage.getItem('user_token');
}

// Remove token from localStorage
export function removeToken() {
  localStorage.removeItem('user_token');
}

// Save credentials to localStorage
export function saveCredentials(credentials) {
  localStorage.setItem('user_credentials', JSON.stringify(credentials));
}

// Get credentials from localStorage
export function getCredentials() {
  const credentials = localStorage.getItem('user_credentials');
  return credentials ? JSON.parse(credentials) : null;
}

// Remove credentials from localStorage
export function removeCredentials() {
  localStorage.removeItem('user_credentials');
}
