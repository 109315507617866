import moment from 'moment';

export const convertHourFormat = (format, hour) => {
  if (format !== 12 && format !== 24) throw 'Invalid time format.';
  if (!hour) throw 'Invalid hour.';
  switch (format) {
    case 12:
      return moment(hour, 'hh:mm A').format('hh:mm A');
    case 24:
      return moment(hour, ['h:mm A']).format('HH:mm');
  }
};
export const statusClass = (status) => {
  return [
    {
      'p-tag': !status || status > 3,
      'p-tag p-tag-success': status === 1,
      'p-tag p-tag-danger': status === 2,
      'p-tag p-tag-warning': status === 3
    }
  ];
};
export const separate = (number) =>
{
  if (!number) return '0';
  return Number(number).toLocaleString();
}
export const normalizeGetTableQuery = (query, itemId , itemType) => {
  const searchable_columnsSample = query.filters ? Object.keys(query.filters) : [];
  if (searchable_columnsSample[0] === 'global') searchable_columnsSample.splice(0, 1);
  const convertedQuery = {
    searchable_columns: searchable_columnsSample,
    dt_params: {
      page: Number(query.page),
      rows: Number(query.rows),
      sortField: null,
      sortOrder: null,
      filters: query.filters ? (query.filters) : {}
    }
  };
  if (typeof itemId !== 'undefined' && typeof itemType !== 'undefined') {
    if (!convertedQuery.searchable_columns.includes(itemType )) {
      convertedQuery.searchable_columns.push(itemType );
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    convertedQuery.dt_params.filters[itemType] = {
      operator: 'and',
      constraints: [{ value: itemId, matchMode: 'equals' }]
    };
  }
  if (query.sortField) (convertedQuery.dt_params.sortField ) = query.sortField;
  if (query.sortOrder) convertedQuery.dt_params.sortOrder = Number(query.sortOrder);
  return {
    dt_params: JSON.stringify(convertedQuery.dt_params),
    searchable_columns: JSON.stringify(convertedQuery.searchable_columns)
  };
};

export const capitalize = (text) => {
  text = text.toLowerCase();
  return text
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};
export const convertIsoDateToString = (isoDate, format) => {
  if (!format) format = 'YYYY-MM-DD';
  return moment(isoDate).format(format);
};
export const convertIsoDateToStringFull = (isoDate, format) => {
  if (!format) format = 'YYYY-MM-DD, hh:mm A';
  return moment(isoDate).format(format);
};
export const convertIsoDateFromNow = (isoDate) => {
  return moment(isoDate).fromNow();
};
export const downloadFileFromRequest = (headers, file, format) => {
  switch (format) {
    case 'csv':
      format = 'text/csv';
      break;
    case 'excel':
      format = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      break;
    default:
      format = '';
      break;
  }
  if (!format || format === '') throw new Error('Invalid file type');
  /* eslint-disable */
  const filename = headers['content-disposition'].split('filename=')[1].split('.')[0];
  const extension = headers['content-disposition'].split('.')[1].split(';')[0];
  const url = window.URL.createObjectURL(new Blob([file], { type: format }));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename + extension);
  document.body.appendChild(link);
  link.click();
  /* eslint-disable */
};
export const getFileNameFromUrl = (url) => {
  return url.substring(url.lastIndexOf('/') + 1);
};
export const getFileExtensionFromFileName = (fileName) => {
  return fileName.substring(fileName.lastIndexOf('.') + 1);
};
export const isBlob = (response) => {
  const jsonMimeType = 'application/json';
  const dataType = (response.data as { type }).type;
  return response.data instanceof Blob && dataType !== jsonMimeType;
};
export const dayConvertor = (day: number) => {
  switch (day) {
    case 6:
      return 'Saturday';
    case 7:
      return 'Sunday';
    case 1:
      return 'Monday';
    case 2:
      return 'Tuesday';
    case 3:
      return 'Wednesday';
    case 4:
      return 'Thursday';
    case 5:
      return 'Friday';
  }
  return '';
};
export const globalAxiosErrorHandler = (error) => {
  const ErrorObject = {
    summary: '',
    detail: ''
  };
  if (!error.response || !error.response.data || !error.response.data.errors) {
    ErrorObject.summary = 'Error';
    ErrorObject.detail = 'This is an unexpected error, please notify IT';
  } else {
    ErrorObject.summary = 'Error: ' + error.response.data.message;
    for (const property in error.response.data.errors) {
      ErrorObject.detail += `${property}: ${error.response.data.errors[property]} \n`;
    }
  }
  return ErrorObject;
};

export const copyLinkToClipboard = (linkElement: HTMLAnchorElement): void => {
  if (!navigator.clipboard) {
    const textArea: HTMLTextAreaElement = document.createElement('textarea');
    textArea.value = linkElement.href;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    alert('Link copied to clipboard');
  } else {
    void navigator.clipboard.writeText(linkElement.href).then((): void => {
      alert('Link copied to clipboard');
    });
  }
};