<template>
  <div class="order-packages w-full">
    <div class="w-full text-right">
      <Button class="p-button p-button-outlined mt-3 mr-4" label="افزودن بار" @click="addMorePackage" />
    </div>

    <div v-for="(item, index) in OrderPackageItems" :key="index" class="package-item grid mb-5">
      <div class="col-12">
        <div class="grid text-right">
          <div class="col-12 md:col-6">
            <div class="flex align-items-center gap-3 mb-3">
              <label for="product_name" class="w-8rem">نام محصول</label>
              <InputText
                required
                v-model="item.product_name"
                id="product_name"
                class="flex-auto"
                autocomplete="off"
                placeholder="لطفا نام محصول را وارد نمایید"
              />
            </div>
          </div>
          <div class="col-12 md:col-6">
            <div class="flex align-items-center gap-3 mb-3">
              <label for="net_weight" class="w-10rem">وزن بدون بسته بندی</label>
              <InputText
                required
                v-model="item.net_weight"
                id="net_weight"
                class="flex-auto"
                autocomplete="off"
                placeholder="لطفا وزن بدون بسته بندی را وارد نمایید"
              />
            </div>
          </div>
          <div class="col-12 md:col-6">
            <div class="flex align-items-center gap-3 mb-3">
              <label for="gross_weight" class="w-8rem">وزن با بسته بندی</label>
              <InputText
                required
                v-model="item.gross_weight"
                id="gross_weight"
                class="flex-auto"
                autocomplete="off"
                placeholder="لطفا وزن با بسته بندی را وارد نمایید"
              />
            </div>
          </div>
          <div class="col-12 md:col-6">
            <div class="flex align-items-center gap-3 mb-3">
              <label for="count" class="w-10rem">تعداد بسته</label>
              <InputText
                required
                v-model="item.count"
                id="count"
                class="flex-auto"
                autocomplete="off"
                placeholder="لطفا تعداد بسته را وارد نمایید"
              />
            </div>
          </div>
          <div class="col-12 md:col-6">
            <div class="flex align-items-center gap-3 mb-3">
              <label for="count_item" class="w-8rem">تعداد محصول</label>
              <InputText
                required
                v-model="item.count_item"
                id="count_item"
                class="flex-auto"
                autocomplete="off"
                placeholder="لطفا تعداد محصول را وارد نمایید"
              />
            </div>
          </div>
          <div class="col-12 md:col-6">
            <div class="flex align-items-center gap-3 mb-3">
              <label for="value" class="w-10rem">ارزش کالا</label>
              <InputText
                required
                v-model="item.value"
                id="value"
                class="flex-auto"
                autocomplete="off"
                placeholder="لطفا ارزش کالا را وارد نمایید"
              />
            </div>
          </div>
          <div class="col-12 md:col-6">
            <div class="flex align-items-center gap-3 mb-3">
              <label for="hs_code" class="w-8rem">کد HS</label>
              <InputText
                required
                v-model="item.hs_code"
                id="hs_code"
                class="flex-auto"
                autocomplete="off"
                placeholder="لطفا کد HS را وارد نمایید"
              />
            </div>
          </div>
          <div class="col-12 mt-3">
            <div class="grid">
              <div class="col-12 md:col-4">
                <div class="flex align-items-center gap-3 mb-3">
                  <label for="width" class="w-3rem">عرض</label>
                  <InputText required v-model="item.width" id="width" class="flex-auto" autocomplete="off" />
                </div>
              </div>
              <div class="col-12 md:col-4">
                <div class="flex align-items-center gap-3 mb-3">
                  <label for="length" class="w-3rem">طول</label>
                  <InputText required v-model="item.length" id="length" class="flex-auto" autocomplete="off" />
                </div>
              </div>
              <div class="col-12 md:col-4">
                <div class="flex align-items-center gap-3 mb-3">
                  <label for="height" class="w-3rem">ارتفاع</label>
                  <InputText required v-model="item.height" id="height" class="flex-auto" autocomplete="off" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="remove-item">
        <Button
          class="p-button p-button-icon-only p-button-danger p-button-rounded"
          icon="pi pi-trash"
          @click="removeOrderPackage(index)"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from 'vue';
import emitter from '@/utils/eventBus';

const orderPackageSampleItem = {
  product_name: undefined,
  net_weight: undefined,
  gross_weight: undefined,
  count: undefined,
  count_item: undefined,
  value: undefined,
  hs_code: undefined,
  width: undefined,
  length: undefined,
  height: undefined
};
const OrderPackageItems = ref([]);
const props = defineProps({
  orderId: {
    type: Number,
    required: true
  }
});
const addMorePackage = () => {
  OrderPackageItems.value.push({
    product_name: undefined,
    net_weight: undefined,
    gross_weight: undefined,
    count: undefined,
    count_item: undefined,
    value: undefined,
    hs_code: undefined,
    width: undefined,
    length: undefined,
    height: undefined
  });
};
const removeOrderPackage = (index) => {
  if (OrderPackageItems.value.length > 1) {
    OrderPackageItems.value.splice(index, 1);
  } else {
    emitter.emit('toast', { type: 'error', message: 'اطلاعات حداقل یک بسته الزامیست' });
  }
};
onMounted(() => {
  OrderPackageItems.value.push(orderPackageSampleItem);
});
</script>
<style scoped lang="scss">
.package-item {
  margin: 20px 20px 20px 20px;
  padding: 40px 20px 20px 20px;
  background-color: #fcfcfc;
  border: 2px solid #eee;
  border-radius: 8px;
}
.remove-item {
  position: absolute;
  margin-top: -60px;
  left: 8.1%;
}
</style>
