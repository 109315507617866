<template>
  <div class="layout-wrapper" :class="containerClass">
    <app-topbar></app-topbar>
    <div class="layout-sidebar">
      <app-sidebar></app-sidebar>
    </div>
    <div class="layout-main-container">
      <div class="layout-main">
        <router-view></router-view>
      </div>
      <app-footer></app-footer>
    </div>
    <app-config></app-config>
    <div class="layout-mask"></div>
  </div>
  <Toast />
</template>

<script setup>
import { computed, watch, ref } from 'vue';
import AppTopbar from './AppTopbar.vue';
import AppFooter from './AppFooter.vue';
import AppSidebar from './AppSidebar.vue';
import AppConfig from './AppConfig.vue';
import { useLayout } from '@/layout/composables/layout';
import { useToast } from 'primevue/usetoast';
import emitter from '@/utils/eventBus';
import { globalAxiosErrorHandler } from '@/utils/globalHelpers';

const toast = useToast();

const { layoutConfig, layoutState, isSidebarActive } = useLayout();

const outsideClickListener = ref(null);

watch(isSidebarActive, (newVal) => {
  if (newVal) {
    bindOutsideClickListener();
  } else {
    unbindOutsideClickListener();
  }
});

const containerClass = computed(() => {
  return {
    'layout-theme-light': layoutConfig.darkTheme.value === 'light',
    'layout-theme-dark': layoutConfig.darkTheme.value === 'dark',
    'layout-overlay': layoutConfig.menuMode.value === 'overlay',
    'layout-static': layoutConfig.menuMode.value === 'static',
    'layout-static-inactive': layoutState.staticMenuDesktopInactive.value && layoutConfig.menuMode.value === 'static',
    'layout-overlay-active': layoutState.overlayMenuActive.value,
    'layout-mobile-active': layoutState.staticMenuMobileActive.value,
    'p-ripple-disabled': layoutConfig.ripple.value === false
  };
});
const bindOutsideClickListener = () => {
  if (!outsideClickListener.value) {
    outsideClickListener.value = (event) => {
      if (isOutsideClicked(event)) {
        layoutState.overlayMenuActive.value = false;
        layoutState.staticMenuMobileActive.value = false;
        layoutState.menuHoverActive.value = false;
      }
    };
    document.addEventListener('click', outsideClickListener.value);
  }
};
const unbindOutsideClickListener = () => {
  if (outsideClickListener.value) {
    document.removeEventListener('click', outsideClickListener);
    outsideClickListener.value = null;
  }
};
const isOutsideClicked = (event) => {
  const sidebarEl = document.querySelector('.layout-sidebar');
  const topbarEl = document.querySelector('.layout-menu-button');

  return !(
    sidebarEl.isSameNode(event.target) ||
    sidebarEl.contains(event.target) ||
    topbarEl.isSameNode(event.target) ||
    topbarEl.contains(event.target)
  );
};
emitter.on('toast', (data) => showToast(data));
emitter.on('toast-axios', (error) => showAxiosToast(error));
const showAxiosToast = (error) => {
  toast.add({
    severity: 'error',
    summary: globalAxiosErrorHandler(error).summary,
    detail: globalAxiosErrorHandler(error).detail,
    life: 5000
  });
};

const showToast = (data) => {
  switch (data.type) {
    case 'info':
      toast.add({
        severity: data.type,
        summary: data.title ? data.title : 'Attention',
        detail: data.message,
        life: 2000
      });
      break;
    case 'success':
      toast.add({
        severity: data.type,
        summary: data.title ? data.title : 'Successful operation',
        detail: data.message,
        life: 5000
      });
      break;
    case 'warn':
      toast.add({
        severity: data.type,
        summary: data.title ? data.title : 'Warning',
        detail: data.message,
        life: 4000
      });
      break;
    case 'error':
      toast.add({
        severity: data.type,
        summary: data.title ? data.title : 'Error',
        detail: data.message,
        life: 6000
      });
      break;
  }
};
</script>

<style lang="scss" scoped></style>
