<template>
  <Button
    v-if="actionType === 'add'"
    icon="pi pi-plus"
    class="p-button p-button-outlined mr-4 mt-3"
    label="افزودن آدرس جدید"
    @click="toggleModal"
  />
  <Button v-else class="p-button-icon-only p-button-outlined badge-button mr-2" @click="toggleModal">
    <i class="pi pi-pencil"></i>
  </Button>

  <Dialog
    v-model:visible="visible"
    modal
    :header="(actionType === 'add' ? 'ایجاد ' : 'ویرایش ') + 'آدرس'"
    :style="{ width: '80rem', direction: 'rtl' }"
  >
    <div class="card pb-0">
      <div class="grid">
        <div class="col-12">
          <div class="flex align-items-center gap-3 mb-3">
            <label for="address" class="w-6rem">آدرس</label>
            <Textarea v-model="currentData.address" id="address" class="flex-auto w-full" autocomplete="off" />
          </div>
        </div>

        <div class="col-12 md:col-6">
          <div class="flex align-items-center gap-3 mb-3">
            <label for="origin_country_id" class="w-7rem">کشور</label>
            <SelectInput v-model="currentData.country_id" placeholder="انتخاب کشور ..." type-primary="Country" />
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div class="flex align-items-center gap-3 mb-3">
            <label for="origin_id" class="w-7rem">شهر</label>
            <SelectInput
              v-model="currentData.city_id"
              placeholder="انتخاب شهر ..."
              :id-primary="currentData.country_id"
              :disabled="!currentData.country_id"
              type-primary="City"
            />
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div class="flex align-items-center gap-3 mb-3">
            <label for="postal_code" class="w-7rem">کد پستی</label>
            <InputText
              v-model="currentData.postal_code"
              id="postal_code"
              class="w-full"
              placeholder="لطفا کد پستی را وارد نمایید"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-content-end gap-2">
      <Button type="button" label="انصراف" severity="secondary" @click="visible = false"></Button>
      <Button type="button" label="ذخیره" @click="saveData"></Button>
    </div>
  </Dialog>
</template>

<script setup>
import { ref } from 'vue';
import { CustomerService } from '@/service/CustomerService';
import emitter from '@/utils/eventBus';
import SelectInput from '@/components/Global/SelectInput.vue';
const CustomerServiceItem = ref(new CustomerService());
const currentData = ref({});
const visible = ref(false);
const props = defineProps({
  prevData: {
    default: () => {
      return {};
    },
    type: Object,
    required: false
  },
  actionType: {
    type: String,
    required: true
  },
  customerId: {
    type: Number,
    required: true
  }
});

const saveData = () => {
  currentData.value.customer_id = props.customerId;
  if (props.actionType === 'add') {
    CustomerServiceItem.value
      .createCustomerAddress(currentData.value)
      .then(() => {
        emitter.emit('toast', { type: 'success', message: 'آدرس با موفقیت ایجاد شد' });
        visible.value = false;
        emitter.emit('refresh-complete-information');
      })
      .catch((error) => {
        emitter.emit('toast-axios', error);
      });
  } else {
    CustomerServiceItem.value
      .editCustomerAddress(props.prevData.id, currentData.value)
      .then(() => {
        emitter.emit('toast', { type: 'success', message: 'آدرس با موفقیت بروزرسانی شد' });
        visible.value = false;
        emitter.emit('refresh-complete-information');
      })
      .catch((error) => {
        emitter.emit('toast-axios', error);
      });
  }
};
const toggleModal = () => {
  visible.value = !visible.value;
  if (props.actionType === 'edit') {
    currentData.value = {
      address: props.prevData.address,
      postal_code: props.prevData.postal_code,
      city_id: props.prevData.city_id,
      country_id: props.prevData.city.country.id,
      customer_id: props.customerId
    };
  } else {
    currentData.value = {};
  }
};
defineExpose({
  toggleModal
});
</script>
<style scoped lang="scss">
.badge-button {
  vertical-align: middle;
  height: 26px !important;
  width: 26px !important;
}
</style>
