import axios from 'axios';
import { getToken, removeToken } from '@/utils/auth';
import { logout } from './logout.js';

export const mainAxios = () => {
  axios.defaults.baseURL = 'http://api.homaexpressco.com';
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  axios.defaults.headers.common['Accept'] = 'application/json';
  axios.defaults.timeout = 100000;
  axios.defaults.withCredentials = true;
  axios.defaults.withXSRFToken = true;
};

axios.interceptors.request.use((config) => {
  config.headers.authorization = `Bearer ${getToken()}`;
  return config;
});

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && (error.response.status === 401 || error.response.status === 419)) {
      await logout();
      removeToken();
    }
    return Promise.reject(error);
  }
);
export default mainAxios;
