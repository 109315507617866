import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCheckToSlot,
  faHouse,
  faBoxArchive,
  faFileInvoice,
  faBuilding,
  faBookmark,
  faPlaneUp,
  faHome,
  faCity,
  faEarthAsia,
  faGear,
  faChevronLeft,
  faBox,
  faRectangleList,
  faShuffle,
  faCreditCard,
  faTruck,
  faBarsStaggered,
  faTruckArrowRight,
  faList,
  faFileInvoiceDollar,
  faChartSimple
} from '@fortawesome/free-solid-svg-icons';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(
  faCheckToSlot,
  faHouse,
  faBoxArchive,
  faFileInvoice,
  faBuilding,
  faBookmark,
  faBell,
  faPlaneUp,
  faHome,
  faCity,
  faEarthAsia,
  faGear,
  faChevronLeft,
  faBox,
  faRectangleList,
  faShuffle,
  faCreditCard,
  faTruck,
  faBarsStaggered,
  faTruckArrowRight,
  faList,
  faFileInvoiceDollar,
  faChartSimple
);

export const registerFontawesome = (app) => {
  app.component('FontAwesomeIcon', FontAwesomeIcon);
};
