import axios from 'axios';
import { normalizeGetTableQuery } from '@/utils/globalHelpers';

export class ReferenceService {
  async getPickupList(isPickup, params) {
    return await axios.get('/api/v1/portal/pickup/order/list?isPickup=' + isPickup, {
      params: normalizeGetTableQuery(params)
    });
  }
  async getUserPickupList(isPickup, params) {
    return await axios.get('/api/v1/portal/pickup/listOrderPickup', {
      params: normalizeGetTableQuery(params)
    });
  }
  async getReferences(params) {
    return await axios.get('/api/v1/portal/order', { params: normalizeGetTableQuery(params) });
  }
  async getReferencesTracking(orderId, params) {
    return await axios.get(`/api/v1/portal/order/${orderId}/tracking/`, { params: normalizeGetTableQuery(params) });
  }
  async createReference(data) {
    return await axios.post('/api/v1/portal/order', data);
  }
  async editReference(id, data) {
    return await axios.patch(`/api/v1/portal/order/update/${id}`, data);
  }
  async changeStatus(data) {
    return await axios.post(`/api/v1/portal/order/changeStatus/${data.id}`, data);
  }
  async setReceiverData(data, orderId) {
    return await axios.post(`/api/v1/portal/order/setReceiverInfo/${orderId}`, data);
  }
  async updateOrder(data, orderId) {
    return await axios.patch(`/api/v1/portal/order/update/${orderId}`, data);
  }
  async createOrderTracking(data) {
    return await axios.post(`/api/v1/portal/orderTracking`, data);
  }
  async createInvoice(data) {
    return await axios.post(`/api/v1/portal/invoice`, data);
  }
  async updateOrderTracking(data, statusId) {
    return await axios.put(`api/v1/portal/orderTracking/${statusId}`, data);
  }
  async createBulkOrderTracking(data) {
    console.log(data);
    return await axios.post(`api/v1/portal/orderTracking/setStatusOrders`, data);
  }
}
