import axios from 'axios';

export class CustomerService {
  getCustomersSmall() {
    return fetch('/demo/data/customers-small.json', { headers: { 'Cache-Control': 'no-cache' } })
      .then((res) => res.json())
      .then((d) => d.data);
  }
  getCustomersMedium() {
    return fetch('/demo/data/customers-medium.json', { headers: { 'Cache-Control': 'no-cache' } })
      .then((res) => res.json())
      .then((d) => d.data);
  }

  getCustomersLarge() {
    return fetch('/demo/data/customers-large.json', { headers: { 'Cache-Control': 'no-cache' } })
      .then((res) => res.json())
      .then((d) => d.data);
  }
  async createCustomerAddress(data) {
    return await axios.post('/api/v1/portal/customerAddress', data);
  }
  async editCustomerAddress(id, data) {
    return await axios.put(`api/v1/portal/customerAddress/${id}`, data);
  }
}
