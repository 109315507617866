<template>
  <Dropdown
    v-model="input"
    :options="Options"
    :option-label="label"
    :option-value="returnValue"
    :placeholder="placeholder"
    :filter="showFilter"
    filter-placeholder="Search ..."
    empty-message="..."
    :disabled="props.disabled"
    :loading="isLoading"
    :class="[styles, error ? 'p-invalid' : '']"
    :show-clear="!disabled"
  >
    <template #option="slotProps">
      <div class="p-dropdown-car-option">
        <span>{{ convertDisplayOption(slotProps.option[props.label]) }}</span>
      </div>
    </template>
    <template #dropdownicon>
      <slot name="dropdownicon" />
    </template>
  </Dropdown>
</template>

<script setup lang="ts">
import { ref, computed, onBeforeMount, watch } from 'vue';
import SelectInputService from '../../service/SelectInputService';
import emitter from '@/utils/eventBus';
import { capitalize } from '@/utils/globalHelpers';
const SelectInputServiceItem = ref(new SelectInputService());
const isLoading = ref(false);
const Options = ref([]);
const emit = defineEmits(['update:value', 'update:data']);
const input = computed({
  get() {
    return props.value;
  },
  set(newValue) {
    return emit('update:value', newValue);
  }
});
const props = defineProps({
  value: {
    type: [Number, String],
    required: false,
    default: null
  },
  styles: {
    type: String,
    required: false,
    default: 'w-full'
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false
  },
  error: {
    type: Boolean,
    required: false,
    default: false
  },
  idPrimary: {
    type: [Number, String],
    required: false,
    default: 0
  },
  additionalId: {
    type: [String, Number],
    required: false,
    default: null
  },
  typePrimary: {
    type: String,
    required: true
  },
  returnValue: {
    type: String,
    required: false,
    default: 'id'
  },
  label: {
    type: String,
    required: false,
    default: 'name'
  },
  placeholder: {
    type: String,
    required: true
  },
  showFilter: {
    type: Boolean,
    required: false,
    default: true
  },
  maxContentLength: {
    type: Number,
    required: false,
    default: 300
  },
  preSetOptions: {
    type: Array,
    required: false,
    default: () => []
  },
  optionsSet: {
    type: Array,
    required: false,
    default: () => []
  },
  shouldCapitalize: {
    type: Boolean,
    required: false,
    default: false
  },
  forceLoadData: {
    type: Boolean,
    required: false,
    default: false
  }
});
const loadOptions = () => {
  isLoading.value = true;
  SelectInputServiceItem.value
    .getInputOptions(props.typePrimary, props.idPrimary, props.additionalId)
    .then((data) => {
      if (typeof data === 'undefined') {
        return;
      }
      Options.value = data.data.data;
      if (props.preSetOptions && props.preSetOptions.length) {
        Options.value = props.preSetOptions.concat(Options.value);
      }
      emit('update:data', data?.data);
    })
    .catch((error) => {
      console.log(error);
      emitter.emit('toast-axios', error);
    })
    .finally(() => {
      isLoading.value = false;
    });
};
const convertDisplayOption = (name) => {
  let convertedDisplayOption =
    name.length > props.maxContentLength ? name.substring(0, props.maxContentLength) + '...' : name;
  if (props.shouldCapitalize) convertedDisplayOption = capitalize(convertedDisplayOption);
  return convertedDisplayOption;
};
const loadOptionsInitial = () => {
  if (!props.disabled || props.forceLoadData) {
    if (props.optionsSet && props.optionsSet.length) {
      Options.value = props.optionsSet;
    } else {
      switch (props.typePrimary) {
        case 'QualificationTerm':
          Options.value = [
            { id: 0, name: 'Month-to-Month' },
            { id: 1, name: '12 Months' },
            { id: 2, name: '24 Months' },
            { id: 3, name: '36 Months' },
            { id: 4, name: '48 Months' }
          ];
          break;
        case 'TableSelectedItem':
          Options.value = [
            { id: 0, name: 'Show All' },
            { id: 1, name: 'Show Only Selected' },
            { id: 2, name: 'Show Only Available' }
          ];
          break;
        case 'MonthsOfTheYear':
          Options.value = [
            { id: 1, name: 'January' },
            { id: 2, name: 'February' },
            { id: 3, name: 'March' },
            { id: 4, name: 'April' },
            { id: 5, name: 'May' },
            { id: 6, name: 'June' },
            { id: 7, name: 'July' },
            { id: 8, name: 'August' },
            { id: 9, name: 'September' },
            { id: 10, name: 'October' },
            { id: 11, name: 'November' },
            { id: 12, name: 'December' }
          ];
          break;
        case 'TemBank':
          Options.value = [
            { id: 1, name: 'Wells Fargo' },
            { id: 2, name: 'BofA' }
          ];
          break;
        case 'PollingEngine':
          Options.value = [
            { id: 1, name: 'ENGINE1' },
            { id: 2, name: 'ENGINE2' },
            { id: 3, name: 'ENGINE3' },
            { id: 4, name: 'ENGINE4' }
          ];
          break;
        case 'AttachmentType':
          Options.value = [
            { id: 1, name: 'xlsx' },
            { id: 2, name: 'jpg' },
            { id: 3, name: 'jpeg' },
            { id: 3, name: 'png' },
            { id: 3, name: 'zip' },
            { id: 3, name: 'rar' },
            { id: 3, name: 'yaml' },
            { id: 4, name: 'pdf' }
          ];
          break;
        default:
          loadOptions();
      }
    }
  }
};
watch(
  () => props,
  () => {
    loadOptionsInitial();
  },
  { deep: true }
);
onBeforeMount(() => {
  loadOptionsInitial();
});
</script>
