import axios from 'axios';

export default class SelectInputService {
  async getInputOptions(typePrimary, idPrimary, additionalId) {
    const isEssenceTypeValid = [
      'Country',
      'City',
      'Service',
      'ExtraService',
      'Airline',
      'TrackingStatus',
      'PaymentMethod',
      'BaseLocation',
      'ProductType',
      'SubProductType',
      'CarryingTerms',
      'pickupList',
      'nonDistributionStatus'
    ].includes(typePrimary);
    if (!isEssenceTypeValid) throw new Error('Essence type is not valid');
    switch (typePrimary) {
      case 'Country':
        return await axios.get('/api/v1/portal/country/list');
      case 'City':
        return await axios.get(`api/v1/portal/country/${idPrimary}/cityList`);
      case 'Service':
        return await axios.get(`api/v1/portal/service/listNotExtra`);
      case 'ExtraService':
        return await axios.get(`api/v1/portal/service/listIsExtra`);
      case 'Airline':
        return await axios.get(`api/v1/portal/airline/list`);
      case 'TrackingStatus':
        return await axios.get(`api/v1/portal/trackingStatus/list`);
      case 'PaymentMethod':
        return await axios.get(`api/v1/portal/paymentMethod/list`);
      case 'BaseLocation':
        return await axios.get(`api/v1/portal/location/list`);
      case 'ProductType':
        return await axios.get(`api/v1/portal/productType/list`);
      case 'SubProductType':
        return await axios.get(`api/v1/portal/productType/listChilds/${idPrimary}`);
      case 'CarryingTerms':
        return await axios.get(`api/v1/portal/carryingTerm/list/${idPrimary}`);
      case 'pickupList':
        return await axios.get(`api/v1/portal/pickup/user/pickupList`);
      case 'nonDistributionStatus':
        return await axios.get(`api/v1/portal/nonDistributionStatus/list`);
    }
  }
}
