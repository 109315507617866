<template>
  <div class="surface-ground align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
    <div class="text-center align-items-center justify-content-center p-2 md:p-8">
      <img :src="logoUrl" alt="Sakai logo" class="mb-5 w-15rem flex-shrink-0" />
      <div class="complete-information border-round-3xl">
        <div class="w-full surface-card py-4 px-5 sm:px-4 border-round-3xl">
          <div class="text-center mb-5">
            <span class="text-600 font-medium">لطفا اطلاعات سفارش خود را تکمیل نمایید</span>
          </div>

          <div class="grid formgrid p-4">
            <Stepper class="col-12 order-info-stepper" linear>
              <StepperPanel header="اطلاعات گیرنده و فرستنده">
                <template #content="{ nextCallback }">
                  <div class="flex flex-column">
                    <div class="text-center"><span class="header-title-sender pt-3">فرستنده</span></div>
                    <div
                      class="border-2 border-dashed surface-border border-round flex-auto flex justify-content-center align-items-center font-medium mt-2 mb-4"
                    >
                      <div class="grid formgrid w-full p-3">
                        <div class="col-12 md:col-12">
                          <div class="flex align-items-center gap-3 mb-3">
                            <p>نوع مشتری: {{ customerType(currentData.sender ?? {}) }}</p>
                          </div>
                        </div>

                        <div
                          v-if="currentData.sender && currentData.sender.legal_type === 'person'"
                          class="col-12 md:col-6"
                        >
                          <div class="flex align-items-center gap-3 mb-3">
                            <label for="first_name" class="w-8rem">نام</label>
                            {{ currentData.sender.first_name }}
                          </div>
                        </div>
                        <div
                          v-if="currentData.sender && currentData.sender.legal_type === 'person'"
                          class="col-12 md:col-6"
                        >
                          <div class="flex align-items-center gap-3 mb-3">
                            <label for="last_name" class="w-8rem">نام خانوادگی</label>
                            {{ currentData.sender.last_name }}
                          </div>
                        </div>
                        <div
                          v-if="currentData.sender && currentData.sender.legal_type === 'company'"
                          class="col-12 md:col-6"
                        >
                          <div class="flex align-items-center gap-3 mb-3">
                            <label for="company_name" class="w-8rem">Company Name</label>
                            <InputText
                              v-model="currentData.sender.company_name"
                              id="company_name"
                              class="flex-auto"
                              autocomplete="off"
                              disabled
                            />
                          </div>
                        </div>
                        <div class="col-12 md:col-6">
                          <div class="flex align-items-center gap-3 mb-3">
                            <label for="email" class="w-8rem">آدرس ایمیل</label>
                            <InputText
                              v-model="currentData.sender.email"
                              id="email"
                              class="flex-auto"
                              autocomplete="off"
                              disabled
                            />
                          </div>
                        </div>
                        <div class="col-12 md:col-6">
                          <div class="flex align-items-center gap-3 mb-3">
                            <label for="mobile" class="w-8rem">شماره موبایل</label>
                            <InputText
                              v-model="currentData.sender.mobile"
                              id="mobile"
                              class="flex-auto"
                              autocomplete="off"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="text-center"><span class="header-title">گیرنده</span></div>
                    <div
                      class="border-2 border-dashed surface-border border-round flex-auto flex justify-content-center align-items-center font-medium"
                    >
                      <div class="grid formgrid p-3 w-full">
                        <div class="col-12 md:col-12">
                          <div class="flex align-items-center gap-3 mb-3">
                            <label for="first_name" class="w-8rem">نوع مشتری</label>
                            <div class="grid pt-4">
                              <div class="field-radiobutton col-6">
                                <RadioButton
                                  id="legal_type1"
                                  name="legal_type1"
                                  value="person"
                                  v-model="currentData.legal_type"
                                />
                                <label for="legal_type1" class="mr-2">حقیقی</label>
                              </div>
                              <div class="field-radiobutton col-6">
                                <RadioButton
                                  id="legal_type2"
                                  name="legal_type2"
                                  value="company"
                                  v-model="currentData.legal_type"
                                />
                                <label for="legal_type2" class="mr-2">حقوقی</label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div v-if="currentData.legal_type === 'person'" class="col-12 md:col-6">
                          <div class="flex align-items-center gap-3 mb-3">
                            <label for="first_name" class="w-8rem">نام</label>
                            <InputText
                              v-model="currentData.receiver_first_name"
                              id="first_name"
                              class="flex-auto"
                              autocomplete="off"
                            />
                          </div>
                        </div>
                        <div v-if="currentData.legal_type === 'person'" class="col-12 md:col-6">
                          <div class="flex align-items-center gap-3 mb-3">
                            <label for="last_name" class="w-8rem">نام خانوادگی</label>
                            <InputText
                              v-model="currentData.receiver_last_name"
                              id="last_name"
                              class="flex-auto"
                              autocomplete="off"
                            />
                          </div>
                        </div>
                        <div v-if="currentData.legal_type === 'company'" class="col-12 md:col-6">
                          <div class="flex align-items-center gap-3 mb-3">
                            <label for="company_name" class="w-8rem">نام شرکت</label>
                            <InputText
                              v-model="currentData.company_name"
                              id="company_name"
                              class="flex-auto"
                              autocomplete="off"
                            />
                          </div>
                        </div>
                        <div class="col-12 md:col-6">
                          <div class="flex align-items-center gap-3 mb-3">
                            <label for="email" class="w-8rem">آدرس ایمیل</label>
                            <InputText v-model="currentData.email" id="email" class="flex-auto" autocomplete="off" />
                          </div>
                        </div>
                        <div class="col-12 md:col-6">
                          <div class="flex align-items-center gap-3 mb-3">
                            <label for="mobile" class="w-8rem">شماره موبایل</label>
                            <InputText
                              v-model="currentData.receiver_mobile"
                              id="mobile"
                              class="flex-auto"
                              autocomplete="off"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex pt-4 justify-content-end">
                    <Button
                      label="اطلاعات پستی مبدا و مقصد"
                      @click="
                        nextCallback();
                        saveReceiver();
                      "
                    />
                  </div>
                </template>
              </StepperPanel>
              <StepperPanel header="اطلاعات پستی مبدا و مقصد">
                <template #content="{ prevCallback, nextCallback }">
                  <div class="flex flex-column">
                    <div class="text-center"><span class="header-title">انتخاب آدرس فرستنده</span></div>

                    <div
                      class="border-2 border-dashed surface-border border-round flex-auto flex justify-content-center align-items-center font-medium"
                    >
                      <div class="order-addresses w-full text-right">
                        <AddEditCustomerAddress action-type="add" :customer-id="currentData.sender_id" />
                        <div v-if="senderAddresses.length">
                          <div
                            v-for="(address, index) in senderAddresses"
                            :key="index"
                            class="address-item grid text-right"
                          >
                            <div class="col-1">
                              <RadioButton
                                id="order_type1"
                                name="order_type1"
                                :value="address.id"
                                v-model="selectedSenderAddress"
                                @change="selectSenderAddress"
                              />
                            </div>
                            <div class="col-4">آدرس: {{ address.address }}</div>
                            <div class="col-2">کد پستی: {{ address.postal_code }}</div>
                            <div class="col-2">کشور: {{ address.city.country.fa_name }}</div>
                            <div class="col-2">شهر: {{ address.city.en_name }}</div>
                            <div class="col-1">
                              <EssenceDelete
                                essence-source="badge"
                                essence-type="CustomerAddress"
                                :essence-id="address.id"
                                lang="fa"
                                emitter-name="refresh-complete-information"
                              />
                              <AddEditCustomerAddress
                                :customer-id="currentData.sender_id"
                                action-type="edit"
                                :prev-data="address"
                              />
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <p class="text-center address-not-found">آدرسی برای فرستنده یافت نشد</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-column mt-6">
                    <div class="text-center"><span class="header-title">انتخاب آدرس گیرنده</span></div>

                    <div
                      class="border-2 border-dashed surface-border border-round flex-auto flex justify-content-center align-items-center font-medium"
                    >
                      <div class="order-addresses w-full text-right">
                        <AddEditCustomerAddress action-type="add" :customer-id="currentData.receiver_id" />
                        <div v-if="receiverAddresses.length">
                          <div
                            v-for="(address, index) in receiverAddresses"
                            :key="index"
                            class="address-item grid text-right"
                          >
                            <div class="col-1">
                              <RadioButton
                                id="order_type1"
                                name="order_type1"
                                :value="address.id"
                                v-model="selectedReceiverAddress"
                                @change="selectReceiverAddress"
                              />
                            </div>
                            <div class="col-4">آدرس: {{ address.address }}</div>
                            <div class="col-2">کد پستی: {{ address.postal_code }}</div>
                            <div class="col-2">کشور: {{ address.city.country.fa_name }}</div>
                            <div class="col-2">شهر: {{ address.city.en_name }}</div>
                            <div class="col-1">
                              <EssenceDelete
                                essence-source="badge"
                                essence-type="CustomerAddress"
                                :essence-id="address.id"
                                lang="fa"
                                emitter-name="refresh-complete-information"
                              />
                              <AddEditCustomerAddress
                                :customer-id="currentData.receiver_id"
                                action-type="edit"
                                :prev-data="address"
                              />
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <p class="text-center address-not-found">آدرسی برای گیرنده یافت نشد</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex pt-4 justify-content-between">
                    <Button label="اطلاعات گیرنده و فرستنده" severity="secondary" @click="prevCallback" />
                    <Button label="اطلاعات بار" @click="nextCallback" />
                  </div>
                </template>
              </StepperPanel>
              <StepperPanel header="اطلاعات بار">
                <template #content="{ prevCallback }">
                  <div class="flex flex-column">
                    <div
                      class="border-2 border-dashed surface-border border-round flex-auto flex justify-content-center align-items-center font-medium"
                    >
                      <OrderPackageDetail :order-id="orderId" />
                    </div>
                  </div>
                  <div class="flex pt-4 justify-content-between">
                    <Button label="اطلاعات پستی مبدا و مقصد" severity="secondary" @click="prevCallback" />
                    <Button label="ثبت نهایی" @click="submitPackageInfo" />
                  </div>
                </template>
              </StepperPanel>
            </Stepper>
          </div>
        </div>
      </div>
    </div>
  </div>
  <AppConfig simple />
  <Dialog v-model:visible="visible" modal header="مشخصات" :style="{ width: '80rem', direction: 'rtl' }">
    <div class="card pb-0">
      <div class="grid">
        <div class="col-12">
          <div class="flex align-items-center gap-3 mb-3">
            <Checkbox v-model="isPersonDiffrent" :binary="true" />
            <label for="is_extra">{{
              confirmType === 'sender'
                ? 'آیا شخص ارسال کننده با اطلاعات ثبت شده متفاوت است؟'
                : 'آیا شخص گیرنده با اطلاعات ثبت شده متفاوت است؟'
            }}</label>
          </div>
        </div>

        <div v-if="isPersonDiffrent" class="col-12 md:col-6">
          <div class="flex align-items-center gap-3 mb-3">
            <label for="postal_code" class="w-10rem">نام و نام خانوادگی</label>
            <InputText
              v-model="diffrentPersonData.name"
              id="postal_code"
              class="w-full"
              placeholder="لطفا نام و نام خانوادگی را وارد نمایید"
            />
          </div>
        </div>
        <div v-if="isPersonDiffrent" class="col-12 md:col-6">
          <div class="flex align-items-center gap-3 mb-3">
            <label for="mobile" class="w-7rem">شماره تماس</label>
            <InputText
              v-model="diffrentPersonData.mobile"
              id="mobile"
              class="w-full"
              placeholder="لطفا شماره تماس را وارد نمایید"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-content-end gap-2">
      <Button type="button" label="انصراف" severity="secondary" @click="visible = false"></Button>
      <Button type="button" label="ذخیره" @click="saveData"></Button>
    </div>
  </Dialog>
</template>
<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import AppConfig from '../layout/AppConfig.vue';
import GlobalService from '../service/GlobalService';
import { ReferenceService } from '@/service/ReferenceService';
import { useRoute } from 'vue-router';
import emitter from '@/utils/eventBus';
import AddEditCustomerAddress from '@/components/CustomerComponent/AddEditCustomerAddress.vue';
import EssenceDelete from '@/components/Global/EssenceDelete.vue';
import OrderPackageDetail from '@/components/CustomerComponent/OrderPackageDetail.vue';

const selectedSenderAddress = ref(null);
const selectedReceiverAddress = ref(null);
const GlobalServiceItem = ref(new GlobalService());
const ReferenceServiceItem = ref(new ReferenceService());
const currentData = ref({ sender: {}, receiver: {}, legal_type: 'person' });
const senderAddresses = ref({});
const receiverAddresses = ref({});
const router = useRoute();
const orderId = Number(router.params.id);
const visible = ref(false);
const confirmType = ref(null);
const isPersonDiffrent = ref(false);
const diffrentPersonData = ref({});

const logoUrl = computed(() => {
  return `/layout/images/main-logo.png`;
});

const customerType = (customer) => {
  const customerType = customer.legal_type ?? null;
  switch (customerType) {
    case 'person':
      return 'حقیقی';
    case 'company':
      return 'حقوقی';
    default:
      return '-';
  }
};
const saveReceiver = async () => {
  const data = {
    receiver_first_name: currentData.value.receiver_first_name,
    receiver_last_name: currentData.value.receiver_last_name,
    receiver_mobile: currentData.value.receiver_mobile,
    legal_type: currentData.value.legal_type,
    receiver_phone: currentData.value.receiver_phone,
    email: currentData.value.email,
    company_name: currentData.value.company_name
  };
  ReferenceServiceItem.value
    .setReceiverData(data, orderId)
    .then(() => {
      getOrderInformation()
        .catch(() => {})
        .then(() => {
          senderAddresses.value = currentData.value.sender.addresses;
          receiverAddresses.value = currentData.value.receiver.addresses;
        });
    })
    .catch(() => {})
    .finally(() => {});
};
const getOrderInformation = async () => {
  GlobalServiceItem.value
    .getOrderInformation(orderId)
    .then((data) => {
      currentData.value = data.data.data;
      if (currentData.value.receiver) {
        currentData.value.receiver_first_name = currentData.value.receiver.first_name;
        currentData.value.receiver_last_name = currentData.value.receiver.last_name;
        currentData.value.receiver_mobile = currentData.value.receiver.mobile;
        currentData.value.legal_type = currentData.value.receiver.legal_type;
        currentData.value.receiver_phone = currentData.value.receiver.phone;
        currentData.value.email = currentData.value.receiver.email;
        currentData.value.company_name = currentData.value.receiver.company_name;
      } else {
        currentData.value.legal_type = 'person';
      }
    })
    .catch(() => {})
    .finally(() => {
      senderAddresses.value = currentData.value.sender.addresses;
      receiverAddresses.value = currentData.value.receiver.addresses;
    });
};

const saveData = async () => {
  const data = {
    address_id: selectedSenderAddress.value,
    name: diffrentPersonData.value.name,
    mobile: diffrentPersonData.value.mobile
  };

  if (confirmType.value === 'sender') {
    setOriginAddress(data);
  } else {
    setDestinationAddress(data);
  }
};
const selectSenderAddress = async () => {
  visible.value = true;
  confirmType.value = 'sender';
};
const selectReceiverAddress = async () => {
  visible.value = true;
  confirmType.value = 'receiver';
};
const setOriginAddress = async (data) => {
  data.address_id = selectedSenderAddress.value;
  GlobalServiceItem.value
    .setOriginAddress(orderId, data)
    .then(() => {
      emitter.emit('toast', { type: 'success', message: 'آدرس با موفقیت انتخاب شد' });
      visible.value = false;
    })
    .catch(() => {})
    .finally(() => {});
};
const setDestinationAddress = async (data) => {
  data.address_id = selectedReceiverAddress.value;
  GlobalServiceItem.value
    .setDestinationAddress(orderId, data)
    .then(() => {
      emitter.emit('toast', { type: 'success', message: 'آدرس با موفقیت انتخاب شد' });
      visible.value = false;
    })
    .catch(() => {})
    .finally(() => {});
};
const submitPackageInfo = async (data) => {
  GlobalServiceItem.value
    .setOrderPackageInfo(orderId, data)
    .then(() => {
      emitter.emit('toast', { type: 'success', message: 'آدرس با موفقیت انتخاب شد' });
      visible.value = false;
    })
    .catch(() => {})
    .finally(() => {});
};
emitter.on('refresh-complete-information', () => refreshTable());
const refreshTable = () => {
  getOrderInformation();
};

onMounted(() => {
  getOrderInformation();
});
</script>

<style scoped lang="scss">
.pi-eye {
  transform: scale(1.6);
  margin-right: 1rem;
}

.pi-eye-slash {
  transform: scale(1.6);
  margin-right: 1rem;
}
.complete-information {
  padding: 0.3rem;
  background: linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%);
}
.order-info-stepper {
  direction: rtl;
  ::v-deep(.p-stepper-title) {
    margin-right: 10px;
  }
  ::v-deep(.p-stepper-title) {
    font-family: iransans, serif;
  }
}
.header-title {
  background-color: white;
  top: 12px;
  padding: 0 20px;
  z-index: 9;
  position: relative;
  text-align: center;
  font-size: 20px;
  color: #673ab7;
  font-weight: 600;
}
.header-title-sender {
  background-color: white;
  top: 20px;
  padding: 0 20px;
  z-index: 99999999999;
  position: relative;
  text-align: center;
  font-size: 20px;
  color: #673ab7;
  font-weight: 600;
}
.address-item {
  border: 1px solid #eee;
  padding: 20px 10px;
  margin: 20px;
  border-radius: 8px;
  background-color: #f7f7f7;
}
.p-button .p-button-icon-left {
  margin-left: 0.5rem !important;
}
.address-not-found {
  margin-bottom: 60px;
  color: #aeaeae;
  font-size: 18px;
}
</style>
