import { createRouter, createWebHistory } from 'vue-router';
import AppLayout from '@/layout/AppLayout.vue';
import { getToken } from '../utils/auth';
import Login from '@/views/Login.vue';
import CompleteInformation from '@/views/CompleteInformation.vue';
import CustomerLayout from '@/layout/CustomerLayout.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/login',
      component: Login,
      meta: { requiresAuth: false }
    },
    {
      path: '/order-information/',
      component: CustomerLayout,
      meta: { requiresAuth: true },
      children: [
        {
          path: ':id',
          name: 'completeInformation',
          component: CompleteInformation,
          meta: { requiresAuth: false }
        }
      ]
    },
    {
      path: '/',
      component: AppLayout,
      meta: { requiresAuth: true },
      children: [
        {
          path: '/',
          name: 'dashboard',
          component: () => import('@/views/Dashboard.vue')
        },
        {
          path: '/countries',
          name: 'Countries',
          component: () => import('@/views/Country.vue')
        },
        {
          path: '/cities',
          name: 'Cities',
          component: () => import('@/views/City.vue')
        },
        {
          path: '/airlines',
          name: 'Airlines',
          component: () => import('@/views/Airline.vue')
        },
        {
          path: '/services',
          name: 'Services',
          component: () => import('@/views/Service.vue')
        },
        {
          path: '/package-items',
          name: 'PackageItems',
          component: () => import('@/views/PackageItem.vue')
        },
        {
          path: '/tracking-status',
          name: 'TrackingStatus',
          component: () => import('@/views/TrackingStatus.vue')
        },
        {
          path: '/payment-methods',
          name: 'PaymentMethods',
          component: () => import('@/views/PaymentMethod.vue')
        },
        {
          path: '/base-locations',
          name: 'BaseLocations',
          component: () => import('@/views/BaseLocation.vue')
        },
        {
          path: '/carrying-term',
          name: 'CarryingTerm',
          component: () => import('@/views/CarryingTerm.vue')
        },
        {
          path: '/references',
          name: 'References',
          component: () => import('@/views/References.vue')
        },
        {
          path: '/references/:id/tracking',
          name: 'ReferencesTracking',
          component: () => import('@/views/ReferencesTracking.vue')
        },
        {
          path: '/references/:id/print-reception',
          name: 'ReferencesPrintReception',
          component: () => import('@/views/ReferencesReceptionPrint.vue')
        },
        {
          path: '/invoices',
          name: 'Invoices',
          component: () => import('@/views/Invoices.vue')
        },
        {
          path: '/invoices/:id',
          name: 'ShowInvoice',
          component: () => import('@/views/ShowInvoice.vue')
        },
        {
          path: '/bulk-tracking',
          name: 'BulkTrackingStore',
          component: () => import('@/views/ReferenceTrackingBulkAction.vue')
        },
        {
          path: '/pickup-list',
          name: 'PickupList',
          component: () => import('@/views/PickupList.vue')
        },
        {
          path: '/my-pickups',
          name: 'MyPickups',
          component: () => import('@/views/UserPickupList.vue')
        },
        {
          path: '/reports',
          meta: { requiresAuth: true },
          children: [
            {
              path: '/daily-report-traffic-income',
              name: 'DailyReportTrafficIncomeAverage',
              component: () => import('@/views/reports/DailyReportTrafficIncomeAverage.vue')
            },
            {
              path: '/date-report-traffic-income',
              name: 'DateReportTrafficIncomeAverage',
              component: () => import('@/views/reports/DateReportTrafficIncomeAverage.vue')
            },
            {
              path: '/undelivered-package-report',
              name: 'UndeliveredPackageReport',
              component: () => import('@/views/reports/UndeliveredPackageReport.vue')
            },
            {
              path: '/cod-service-report',
              name: 'CodServiceReport',
              component: () => import('@/views/reports/TrafficReportAndFareServiceRevenueAtTheDestination.vue')
            },
            {
              path: '/traffic-revenue-average-rate-reports',
              name: 'TrafficRevenueAverageRateReport',
              component: () => import('@/views/reports/ShipmentSeparationReport.vue')
            },
            {
              path: '/stock-in-customs',
              name: 'StockInCustoms',
              component: () => import('@/views/reports/StockInCustoms.vue')
            },
            {
              path: '/total-count-income-month',
              name: 'TrafficPerformanceReportAndDailyRevenuePerMonth',
              component: () => import('@/views/reports/TrafficPerformanceReportAndDailyRevenuePerMonth.vue')
            },
            {
              path: '/average-shipping-international-time',
              name: 'ShipmentTrendMatrixReportInternational',
              component: () => import('@/views/reports/ShipmentTrendMatrixReportInternational.vue')
            },
            {
              path: '/delivered-package-report',
              name: 'DeliverPackageReport',
              component: () => import('@/views/reports/DeliverPackageReport.vue')
            },
            {
              path: '/average-shipping-time',
              name: 'ShipmentTrendMatrixReport',
              component: () => import('@/views/reports/ShipmentTrendMatrixReport.vue')
            },
            {
              path: '/traffic-income-average-per-package',
              name: 'TrafficReportIncomeAndAverageRateByShipment',
              component: () => import('@/views/reports/TrafficReportIncomeAndAverageRateByShipment.vue')
            },
            {
              path: '/traffic-report-of-incoming-shipment-by-province',
              name: 'TrafficReportOfIncomingShipmentByProvince',
              component: () => import('@/views/reports/TrafficReportOfIncomingShipmentByProvince.vue')
            },
            {
              path: '/report-per-weight-and-package-type',
              name: 'ReportPerWeightAndPackageType',
              component: () => import('@/views/reports/ReportPerWeightAndPackageType.vue')
            },
            {
              path: '/report-revenue-from-suspended-counters',
              name: 'TrafficReportOfReServiceRevenueFromSuspendedCounters',
              component: () => import('@/views/reports/TrafficReportOfReServiceRevenueFromSuspendedCounters.vue')
            },
            {
              path: '/national-code-and-postal-code-report',
              name: 'PostalCodeStatisticsReportAndRecipientAndSenderNationalCode',
              component: () => import('@/views/reports/PostalCodeStatisticsReportAndRecipientAndSenderNationalCode.vue')
            },
            {
              path: '/expired-delivery-report',
              name: 'ShipmentReportWithExpirationDateOutsideTheCommitment',
              component: () => import('@/views/reports/ShipmentReportWithExpirationDateOutsideTheCommitment.vue')
            },
            {
              path: '/non-distribution-report',
              name: 'ReportAccordingToNonDistribution',
              component: () => import('@/views/reports/ReportAccordingToNonDistribution.vue')
            },
            {
              path: '/delivered-package-percent-report',
              name: 'DeliveredPackagePercentReport',
              component: () => import('@/views/reports/DeliveredPackagePercentReport.vue')
            }
          ]
        },
        {
          path: '/pending-pickup-list',
          name: 'PendingPickupList',
          component: () => import('@/views/PendingPickupList.vue')
        },
        {
          path: '/uikit/formlayout',
          name: 'formlayout',
          component: () => import('@/views/uikit/FormLayout.vue')
        },
        {
          path: 'development',
          name: 'Development',
          component: () => import('@/views/Development.vue')
        },
        {
          path: '/uikit/input',
          name: 'input',
          component: () => import('@/views/uikit/Input.vue')
        },
        {
          path: '/uikit/floatlabel',
          name: 'floatlabel',
          component: () => import('@/views/uikit/FloatLabel.vue')
        },
        {
          path: '/uikit/invalidstate',
          name: 'invalidstate',
          component: () => import('@/views/uikit/InvalidState.vue')
        },
        {
          path: '/uikit/button',
          name: 'button',
          component: () => import('@/views/uikit/Button.vue')
        },
        {
          path: '/uikit/table',
          name: 'table',
          component: () => import('@/views/uikit/Table.vue')
        },
        {
          path: '/uikit/list',
          name: 'list',
          component: () => import('@/views/uikit/List.vue')
        },
        {
          path: '/uikit/tree',
          name: 'tree',
          component: () => import('@/views/uikit/Tree.vue')
        },
        {
          path: '/uikit/panel',
          name: 'panel',
          component: () => import('@/views/uikit/Panels.vue')
        },

        {
          path: '/uikit/overlay',
          name: 'overlay',
          component: () => import('@/views/uikit/Overlay.vue')
        },
        {
          path: '/uikit/media',
          name: 'media',
          component: () => import('@/views/uikit/Media.vue')
        },
        {
          path: '/uikit/menu',
          component: () => import('@/views/uikit/Menu.vue'),
          children: [
            {
              path: '/uikit/menu',
              component: () => import('@/views/uikit/menu/PersonalDemo.vue')
            },
            {
              path: '/uikit/menu/seat',
              component: () => import('@/views/uikit/menu/SeatDemo.vue')
            },
            {
              path: '/uikit/menu/payment',
              component: () => import('@/views/uikit/menu/PaymentDemo.vue')
            },
            {
              path: '/uikit/menu/confirmation',
              component: () => import('@/views/uikit/menu/ConfirmationDemo.vue')
            }
          ]
        },
        {
          path: '/uikit/message',
          name: 'message',
          component: () => import('@/views/uikit/Messages.vue')
        },
        {
          path: '/uikit/file',
          name: 'file',
          component: () => import('@/views/uikit/File.vue')
        },
        {
          path: '/uikit/charts',
          name: 'charts',
          component: () => import('@/views/uikit/Chart.vue')
        },
        {
          path: '/uikit/misc',
          name: 'misc',
          component: () => import('@/views/uikit/Misc.vue')
        },
        {
          path: '/blocks',
          name: 'blocks',
          component: () => import('@/views/utilities/Blocks.vue')
        },
        {
          path: '/utilities/icons',
          name: 'icons',
          component: () => import('@/views/utilities/Icons.vue')
        },
        {
          path: '/pages/timeline',
          name: 'timeline',
          component: () => import('@/views/pages/Timeline.vue')
        },
        {
          path: '/pages/empty',
          name: 'empty',
          component: () => import('@/views/pages/Empty.vue')
        },
        {
          path: '/pages/crud',
          name: 'crud',
          component: () => import('@/views/pages/Crud.vue')
        },
        {
          path: '/documentation',
          name: 'documentation',
          component: () => import('@/views/utilities/Documentation.vue')
        }
      ]
    },
    {
      path: '/landing',
      name: 'landing',
      component: () => import('@/views/pages/Landing.vue')
    },
    {
      path: '/pages/notfound',
      name: 'notfound',
      component: () => import('@/views/pages/NotFound.vue')
    },
    {
      path: '/auth/access',
      name: 'accessDenied',
      component: () => import('@/views/pages/auth/Access.vue')
    },
    {
      path: '/auth/error',
      name: 'error',
      component: () => import('@/views/pages/auth/Error.vue')
    }
  ]
});

router.beforeEach((to, from, next) => {
  const token = getToken();
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!token) {
      router.push('/login');
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
