import axios from 'axios';

export class GlobalService {
  async login(params) {
    return await axios.post('/api/v1/login', params);
  }
  async getOrderInformation(orderId) {
    return await axios.get(`/api/v1/order/${orderId}`);
  }
  async setDestinationAddress(orderId, data) {
    return await axios.post(`/api/v1/portal/order/setReceiverAddress/${orderId}`, data);
  }
  async setOriginAddress(orderId, data) {
    return await axios.post(`/api/v1/portal/order/setOriginAddress/${orderId}`, data);
  }
  async logOut() {
    return await axios.post(`/api/v1/portal/logout`);
  }
  async essenceDelete(essenceType, essenceId) {
    const isEssenceTypeValid = [
      'country',
      'city',
      'airline',
      'service',
      'packageItem',
      'trackingStatus',
      'paymentMethod',
      'baseLocation',
      'reference',
      'CarryingTerms',
      'CustomerAddress'
    ].includes(essenceType);
    if (!isEssenceTypeValid) throw new Error('Essence type is not valid');
    let url = '';
    switch (essenceType) {
      case 'country':
        url = `/api/v1/portal/country/${essenceId}`;
        break;
      case 'city':
        url = `/api/v1/portal/city/${essenceId}`;
        break;
      case 'airline':
        url = `/api/v1/portal/airline/${essenceId}`;
        break;
      case 'service':
        url = `/api/v1/portal/service/${essenceId}`;
        break;
      case 'packageItem':
        url = `/api/v1/portal/packageItem/${essenceId}`;
        break;
      case 'trackingStatus':
        url = `/api/v1/portal/trackingStatus/${essenceId}`;
        break;
      case 'paymentMethod':
        url = `/api/v1/portal/paymentMethod/${essenceId}`;
        break;
      case 'baseLocation':
        url = `/api/v1/portal/location/${essenceId}`;
        break;
      case 'reference':
        url = `/api/v1/portal/order/${essenceId}`;
        break;
      case 'CarryingTerms':
        url = `/api/v1/portal/carryingTerm/${essenceId}`;
        break;
      case 'CustomerAddress':
        url = `/api/v1/portal/customerAddress/${essenceId}`;
        break;
    }
    return await axios.delete(url);
  }
}
export default GlobalService;
